<template>
  <v-row>
    <Message
        :message="message"
        @closeAlert="message.show = false"
    ></Message>
    <v-col cols="12" sm="4" lg="2">
      <h4>Dienstleister Status
        <v-tooltip right color="" max-width="200px">
          <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon class="d-inline-flex" size="20">
                          mdi-help
                        </v-icon>
                      </span>
          </template>
          Lege fest welchen Status ein Dienstleister in Verbindung mit einer Hochzeit besitzen kann. Bspw.
          "Gebucht", "In Verhandlung" etc.
        </v-tooltip>
      </h4>
    </v-col>
    <v-col>
      <div v-for="status of vendorStatus" :key="status.id" class="pb-3">
        {{ status.name }}
        <v-tooltip right color="red">
          <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >
                      <v-icon @click="sureToDeleteDialog = true; selectedId = status.id">
                      mdi-delete
                      </v-icon>
                        </span>
          </template>
          Status löschen
        </v-tooltip>

      </div>
      <SureToDelete
          class="mt-0 pt-0 mb-0 pb-0"
          :dialog="sureToDeleteDialog"

          :text="'Bist du Sicher, dass du den Status unwiderruflich löschen möchtest?'"
          @cancel="sureToDeleteDialog=false"
          @sure="deleteStatus('vendorStatus'); sureToDeleteDialog=false"
      ></SureToDelete>
      <v-row class="align-center">
        <v-col cols="8" md="6">
          <v-text-field placeholder="Neuer Status" v-model="newVendorStatus"></v-text-field>
        </v-col>
        <v-col cols="4" md="6">
            <a  @click="addVendorStatus('vendorStatus', newVendorStatus)" class="d-inline">
              Hinzufügen
            </a>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import {error, msgObj, success} from "@/helper/helper";
const Message = () => import("@/components/generalUI/Message");
const SureToDelete = () => import("@/components/generalUI/SureToDelete");
/**
 * Snackbar to show user successful and not successful tries to edit, create or delete a value
 * @displayName Alert
 */
export default {
  name: "VendorStatus",
  computed: {
    ...mapGetters('settings', {
      vendorStatus: 'vendorStatus',
    }),
  },
  components: {
    Message,
    SureToDelete
  },
  data() {
    return {
      newVendorStatus: '',
      message: msgObj(),
      sureToDeleteDialog:false,
      selectedId:""
    }
  },
  mounted() {
    this.$store.dispatch('settings/getStatus', {
      uid: this.user.id,
    }).catch((err) => {
      this.message = error(err)
    })
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  methods: {
    deleteStatus(statusCollection) {
      this.$store.dispatch('settings/deleteStatus', {
        uid: this.user.id,
        id: this.selectedId,
        statusCollection,
      }).then(() => {
        this.message = success('Status erfolgreich gelöscht.')
      }).catch((err) => {
        this.message = error(err)
      })
    },
    addVendorStatus(statusCollection, name) {
      if (name !== '') {
        this.$store.dispatch('settings/addVendorStatus', {
          uid: this.user.id,
          statusCollection,
          name
        }).then(() => {
          this.newVendorStatus = ''
          this.newCategory = ''
          this.message = success('Feld erfolgreich angelegt.')
        }).catch((err) => {
          this.message = error(err)
        })
      }
    },
  }
}
</script>
